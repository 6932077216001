import { Fragment } from 'react';

import { getEmptyObject } from './util';

function Spanner({ children, as = 'div', getSpanProps = getEmptyObject, ...props }) {
	if (!children) return null;
	let words;
	switch (typeof children) {
		case 'string':
			words = children.split(' ');
			break;
		case 'number':
			words = [children.toString()];
			break;
		case 'object':
			if (Array.isArray(children) && children.every(child => typeof child === 'string')) {
				words = children;
				break;
			}
		// Fall through.
		default:
			throw new Error('Spanner expects a single string as children.');
	}
	const OuterElement = as;
	return (
		<OuterElement {...props}>
			{words.map((word, i) => (
				<Fragment key={i}>
					<span style={{ whiteSpace: 'nowrap' }}>
						{word.split('').map((char, j, { length }) => (
							<span key={j} {...getSpanProps(char, j, length)}>
								{char}
							</span>
						))}
					</span>{' '}
				</Fragment>
			))}
		</OuterElement>
	);
}

export default Spanner;
