import Loading from './Loading';
import Invite from './Invite';

import './App.css';

function App() {
	return (
		<main>
			<Loading />
			<Invite />
		</main>
	);
}

export default App;
