export const getEmptyObject = () => ({});

export function mergeProps(
	{ className: className1, style: style1, ...props1 },
	{ className: className2, style: style2, ...props2 }
) {
	return {
		...props1,
		...props2,
		className: [className1, className2].filter(x => x).join(' '),
		style: { ...style1, ...style2 },
	};
}
