import { useState } from 'react';

import './RsvpForm.css';

const blankEntry = () => ({
	name: '',
	email: '',
	diet: '',
});

function RsvpForm({ onSubmit }) {
	const [entries, setEntries] = useState([blankEntry()]);
	const [canCome, setCanCome] = useState(true);
	const [message, setMessage] = useState('');

	function modifyEntry(i, key) {
		return e =>
			setEntries(prev => {
				const newEntries = [...prev];
				const newEntry = { ...newEntries[i], [key]: e.target.value };
				newEntries[i] = newEntry;
				return newEntries;
			});
	}

	return (
		<>
			<form
				name="rsvp"
				method="POST"
				onSubmit={e => {
					const entryPairs = entries.flatMap((entry, i) =>
						Object.entries(entry).map(([key, value]) => [`${key}-${i + 1}`, value])
					);
					const body = [['form-name', 'rsvp'], ...entryPairs, ['attendance', canCome], ['message', message]]
						.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value || null)}`)
						.join('&');

					fetch('/', {
						method: 'POST',
						headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
						body,
					})
						.then(() => {
							onSubmit(canCome);
						})
						.catch(error => alert(error));
					e.preventDefault();
				}}
			>
				<div className="people">
					{entries.map((entry, i) => (
						<div key={i}>
							<div>
								<label htmlFor={`name-${i + 1}`}>Name</label>
								<input
									required
									type="text"
									id={`name-${i + 1}`}
									name={`name-${i + 1}`}
									value={entry.name}
									onChange={modifyEntry(i, 'name')}
								/>
							</div>
							<div>
								<label htmlFor={`email-${i + 1}`}>Email</label>
								<input
									type="email"
									id={`email-${i + 1}`}
									name={`email-${i + 1}`}
									value={entry.email}
									onChange={modifyEntry(i, 'email')}
								/>
							</div>
							<label htmlFor={`diet-${i + 1}`}>Dietary restrictions</label>
							<input
								type="text"
								id={`diet-${i + 1}`}
								name={`diet-${i + 1}`}
								value={entry.diet}
								onChange={modifyEntry(i, 'diet')}
							/>
						</div>
					))}
				</div>

				<div className="add-people-buttons">
					{entries.length > 1 && (
						<button
							title="Remove last person"
							type="button"
							onClick={() => {
								setEntries(prev => prev.slice(0, -1));
							}}
						>
							-
						</button>
					)}
					<button
						title="Add someone else"
						type="button"
						onClick={() => {
							setEntries(prev => [...prev, blankEntry()]);
						}}
					>
						+
					</button>
				</div>

				<fieldset>
					<legend>Attendance</legend>
					<div>
						<input
							type="radio"
							name="attendance"
							id="yes"
							value="yes"
							checked={canCome}
							onChange={() => setCanCome(true)}
						/>
						<label htmlFor="yes">{entries.length === 1 ? 'I' : 'We'} can come</label>
					</div>
					<div>
						<input
							type="radio"
							name="attendance"
							id="no"
							value="no"
							checked={!canCome}
							onChange={() => setCanCome(false)}
						/>
						<label htmlFor="no">{entries.length === 1 ? 'I' : 'We'} can’t come</label>
					</div>
				</fieldset>
				<div>
					<label htmlFor="message">Message</label>
					<textarea id="message" name="message" value={message} onChange={e => setMessage(e.target.value)} />
				</div>

				<button type="submit" disabled={entries.some(entry => !entry.name)}>
					<span>Submit RSVP</span>
				</button>
			</form>
		</>
	);
}

export default RsvpForm;
