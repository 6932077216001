import { useCallback } from 'react';

import Spanner from './Spanner';
import { getEmptyObject, mergeProps } from './util';

import './Wiggle.css';

function Wiggle({ getSpanProps: externalGetSpanProps = getEmptyObject, ...props }) {
	const getSpanProps = useCallback(
		(_char, i, len) => {
			return mergeProps(
				{
					className: 'wiggle-char',
					style: {
						animationDelay: `-${(4 * i) / len}s`,
						display: 'inline-block',
					},
				},
				externalGetSpanProps(_char, i, len)
			);
		},
		[externalGetSpanProps]
	);

	return <Spanner {...props} getSpanProps={getSpanProps} />;
}

export default Wiggle;
