import { useState, useEffect } from 'react';
import useDimensions from 'react-use-dimensions';

import './FitText.css';

function FitText({ children, as = 'div', className, ...props }) {
	const [parentRef, { width: parentWidth }] = useDimensions({
		liveMeasure: true,
	});
	const [childRef, setChildRef] = useState(null);
	const [fontLoaded, setFontLoaded] = useState(false);
	const [childSize, setChildSize] = useState([]);
	const [childWidth, childHeight] = childSize;

	useEffect(() => {
		document.fonts.load('800 1rem "MD Nichrome"').then(() => setFontLoaded(true));
	});

	useEffect(() => {
		if (childRef && fontLoaded && !childSize.length) {
			const { width, height } = childRef.getBoundingClientRect();
			setChildSize([width, height]);
		}
	}, [childRef, childSize, fontLoaded]);

	const [parentStyle, childStyle] =
		parentWidth && childWidth
			? [
					{ height: (childHeight / childWidth) * parentWidth },
					{
						transform: `scale(${parentWidth / childWidth}, ${parentWidth / childWidth})`,
					},
			  ]
			: [{}, {}];

	const OuterEl = as;
	return (
		<OuterEl
			style={parentStyle}
			className={`${className ? `${className} ` : ''}fit-text-parent`}
			ref={parentRef}
			{...props}
		>
			<span style={childStyle} className="fit-text-child" ref={el => setChildRef(el)}>
				{children}
			</span>
		</OuterEl>
	);
}

export default FitText;
