import { useCallback, useState } from 'react';
import makeCalendarUrls from 'add-event-to-calendar';

import FitText from './FitText';
import Wiggle from './Wiggle';
import RsvpForm from './RsvpForm';

import './Invite.css';

const event = {
	name: 'Riley’s 30th birthday party',
	location: 'https://goo.gl/maps/nvNuENSBBr6SwQrCA',
	details: 'A day of playing games outside.\n\n2115 Simcoe County Rd 124\nDuntroon, ON\nL0M 1H0',
	startsAt: '2021-10-02T12:00:00.000-04:00',
	endsAt: '2021-10-02T20:00:00.000-04:00',
};

function Invite() {
	const [rsvpResult, setRsvpResult] = useState(null);

	const monospaceAmpersand = useCallback(char => (char === '&' ? { className: 'fm' } : {}), []);

	const daysUntil = Math.ceil((new Date(2021, 9, 2) - new Date()) / 86400000);
	const alreadyHappened = daysUntil < 0;

	const calendarUrls = makeCalendarUrls(event);

	return (
		<>
			<h1>
				<FitText as="span">You’re</FitText>
				<FitText as="span">invited</FitText>
			</h1>
			<p>
				to a day of <Wiggle as="span">playing games outside,</Wiggle> to celebrate my 30th birthday. The party{' '}
				{alreadyHappened ? 'was' : 'is'} on Saturday, October 2<sup>nd</sup>
				{alreadyHappened
					? '. Thanks for coming!'
					: `, which is ${daysUntil ? `${daysUntil} days from now` : 'today'}.`}
			</p>
			<p>
				I only want to meet up if it’s safe to do so, so I’ll be keeping up with public health orders. If we
				can’t gather in person, I’ll figure something out for us to do online.
			</p>
			<Wiggle as="h2">Games</Wiggle>
			<p>I’ll work out specifics soon, but for now I’m thinking things like:</p>
			<ul>
				<li>Kickball</li>
				<li>Paper planes</li>
				<li>Frisbee golf</li>
				<li>etc.</li>
			</ul>
			<Wiggle getSpanProps={monospaceAmpersand} as="h2">
				Food & drinks
			</Wiggle>
			<p>
				I’m going to try to figure something out for everyone, but there’s a chance I’ll ask folks to bring a
				picnic. There will definitely be some amount of food!
			</p>
			<p>I have a history of buying not enough food and too much salsa. I will try to do way better this time.</p>
			<Wiggle as="h2">Time</Wiggle>
			<p>
				Saturday, October 2<sup>nd</sup>*, noon to 8:00pm (ish)
			</p>
			<ul>
				<li>
					<a href={calendarUrls.google} target="_blank" rel="noopener noreferrer">
						Add to Google Calendar
					</a>
				</li>
				<li>
					<a href={calendarUrls.ics} target="_blank" rel="noopener noreferrer">
						Add to Apple Calendar
					</a>
				</li>
				<li>
					<a href={calendarUrls.outlook} target="_blank" rel="noopener noreferrer">
						Add to Outlook
					</a>
				</li>
			</ul>
			<p>
				*My actual birthday is October 5<sup>th</sup>, but that’s a Tuesday.
			</p>
			<Wiggle as="h2">Location</Wiggle>
			<p>
				The party will be at{' '}
				<a href="https://goo.gl/maps/nvNuENSBBr6SwQrCA" target="_blank" rel="noopener noreferrer">
					my family’s farm
				</a>
				, which is about a 1.5 hour drive from Toronto.
			</p>
			<iframe
				title="Map of Duntroon"
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d182541.79328693595!2d-80.32762098087487!3d44.367164279103186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882a654c232a9559%3A0xc1b3a15141955d55!2s2115%20Simcoe%20County%20Rd%20124%2C%20Duntroon%2C%20ON%20L0M%201H0!5e0!3m2!1sen!2sca!4v1629345030459!5m2!1sen!2sca"
				height="600"
				className="full-bleed"
				allowFullScreen=""
				loading="lazy"
			/>
			<Wiggle as="h2">Questions?</Wiggle>
			<p>Text me at +1.604.716.7961</p>
			<Wiggle as="h2">RSVP</Wiggle>
			<p>
				Partners and families are invited too! Please let me know they’re coming by clicking the + button as
				much as you need.
			</p>
			<p>
				<strong>
					For everyone’s safety and peace of mind, I’m asking that everyone who attends be fully vaccinated.
				</strong>
			</p>
			{rsvpResult === null ? (
				<RsvpForm onSubmit={setRsvpResult} />
			) : (
				<p className="rsvp-result">
					{rsvpResult ? 'Wooooo see you soon!' : 'Sorry you can’t make it - thanks for RSVPing!'}
				</p>
			)}
			<div className={`infinity${rsvpResult === null ? '' : ' submitted'}`}>
				<FitText as="span">1991-</FitText>
				<FitText as="span">infinity</FitText>
			</div>
		</>
	);
}

export default Invite;
