import { useEffect, useState } from 'react';

import './Loading.css';

const MIN_TIME_MS = 1000;

function Loading() {
	const [elapsed, setElapsed] = useState(0);

	useEffect(() => {
		const initialTime = Date.now();

		const interval = setInterval(() => {
			setElapsed(Date.now() - initialTime);
		}, 60);

		return () => clearInterval(interval);
	}, []);

	// Label beginning as 1991.
	return (
		<div className="loading-outer">
			<div
				style={{
					background: '#000',
					width: `${(elapsed * 100) / MIN_TIME_MS}%`,
				}}
			/>
		</div>
	);
}

export default Loading;
